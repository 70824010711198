import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OurValues from '../components/our-values';
import AboutUs from "../components/about-us"
import Services from "../components/services"
import ContactUs from "../components/contact-us"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AboutUs />
    <OurValues />
    <Services />
  </Layout>
)

export default IndexPage
