
import React from "react";

const Services = () => {

    return (
        <>
            <section id="services" className="section choose-us style-two theme-blue pt-60px">
                <div className="overlay bg-primary opacity-5"></div>
                <div className="overlay bg-cb bg-contain" data-bg-img="assets/images/home-1-shape.png"></div>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-6 mx-auto text-center">
                            <h6 className="subtitle">SERVICES&nbsp;</h6>
                            <h2 className="h3 mb-4">What we do?</h2>
                            <p>We provide a wide range of services; here is just a few.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-speed"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Build Custom Software</h5>
                                        <p>At Code Advanced, we build software designed to specifically address the users'
                                        needs with a high level of excellence both in building and in delivery enabling
                                        our clients to meet the rapidly changing demands of their businesses and stay
                                        competitive.
                                        .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-website"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Project Managment</h5>
                                        <p>We assist our clients in continuous application improvement through optimisation,
                                        devops and infrastructure maintenance. We guide your software through all stages
                                        of product life cycle, from initial business analysis and UX/UI to support.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-computer"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Cloud Migration</h5>
                                        <p>The Code Advanced team will help our clients move data, applications or other
                                        business elements to a cloud computing environment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-idea"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Consultancy</h5>
                                        <p>We work with organisations to see their great ideas successfully delivered by
                                        placing skilled resources into our clients’ project delivery teams to facilitate
                                        delivery and add value.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-support-1"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Support Services</h5>
                                        <p>Our technical and business experts work with our client’s in-house teams to
                                        provide support for software-based solutions they are using .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="feature-item">
                                <div className="row">
                                    <div className="col-auto">
                                        <i className="icon flaticon-maps-and-location"></i>
                                    </div>
                                    <div className="col">
                                        <h5 className="h5 mb-3">Inhouse Solutions</h5>
                                        <p>We are building a line of products designed and tailored to help businesses
                                        achieve their goals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services;