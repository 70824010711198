import React from "react"

class ContactUs extends React.Component {
    constructor() {
        super();

        this.state = {
            name: '',
            email: '',
            message: '',
            phoneNo: '',
            subject: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        const contactname = this.state.name;
        const contactemail = this.state.email;
        const contactmessage = this.state.message;
        const phoneNo = this.state.phoneNo;
        const subject = this.state.subject;

        if (contactname && contactemail && contactmessage && phoneNo) {
            let xhr = new XMLHttpRequest();
            xhr.addEventListener('load', () => {
                this.setState({ name: '', email: '', message: '', phoneNo: '', subject: '' });
            });

            xhr.open('POST', '/api/contactus');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify({ name: contactname, email: contactemail, message: contactmessage, phoneNo, subject }));
        }
    };

    render() {
        return (
            <>
                <section id="contactus" className="section contact-us style-one theme-blue pt-60px pb-40px">
                    <div className="overlay bg-primary opacity-5"></div>
                    <div className="overlay bg-contain bg-cb" data-bg-img="assets/images/home-1-shape.png"></div>
                    <div className="container">
                        <div className="section-header row flex-column align-items-center text-center mb-4">
                            <div className="col-md-7">
                                <h2 className="h3">Contact Us</h2>
                                <p className="mb-4">&nbsp;The Code Advanced team would like to hear from you. Please get in touch!
                        </p>
                            </div>
                        </div>
                        <div className="row row-x-0 flex-column align-items-center">
                            <div className="contact-wrapper col-md-10">
                                <div className="row row-x-0 align-items-stretch">
                                    <div className="col-lg-7 col-xl-8">
                                        <div className="left-column">
                                            <h5 className="h5 mb-5">Send us a Message</h5>
                                            <form id="contactform" name="contactform" data-form-validate="true" method="post"
                                                onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12 form-response"></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-5">
                                                            <label htmlFor="contactname" className="sr-only">Your Name</label>
                                                            <input type="text" name="contactname"
                                                                value={this.state.name}
                                                                onChange={(e) => { this.setState({ ...this.state, name: e.target.value }) }}
                                                                className="form-control form-control-lg form-line"
                                                                placeholder="Your Name" data-rule-required="true"
                                                                data-msg-required="Please type your name." />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-5">
                                                            <label htmlFor="contactemail" className="sr-only">Your Email</label>
                                                            <input type="text" id="contactemail" name="contactemail"
                                                                value={this.state.email}
                                                                onChange={(e) => { this.setState({ ...this.state, email: e.target.value }) }}
                                                                className="form-control form-control-lg form-line"
                                                                placeholder="E-mail" data-rule-required="true"
                                                                data-rule-email="true"
                                                                data-msg-required="Please provide your email address."
                                                                data-msg-email="Please provide valid email address." />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-5">
                                                            <label htmlFor="contactphone" className="sr-only">Your Phone Number</label>
                                                            <input type="text" name="contactphone"
                                                                value={this.state.name}
                                                                onChange={(e) => { this.setState({ ...this.state, phoneNo: e.target.value }) }}
                                                                className="form-control form-control-lg form-line"
                                                                placeholder="Your Phone Number" data-rule-required="true"
                                                                data-msg-required="Please type your phone number." />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-5">
                                                            <label htmlFor="subject" className="sr-only">Subject</label>
                                                            <input type="text" name="subject"
                                                                value={this.state.name}
                                                                onChange={(e) => { this.setState({ ...this.state, subject: e.target.value }) }}
                                                                className="form-control form-control-lg form-line"
                                                                placeholder="Subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-5">
                                                            <label htmlFor="contactmessage" className="sr-only">Message</label>
                                                            <textarea rows="5" id="contactmessage" name="contactmessage"
                                                                value={this.state.message}
                                                                onChange={(e) => { this.setState({ ...this.state, message: e.target.value }) }}
                                                                className="form-control form-control-lg form-line"
                                                                placeholder="Message" data-rule-required="true"
                                                                data-msg-required="Please tell us a little more."></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 theme-blue">
                                                        <div className="h-20px"></div>
                                                        <button type="submit" className="btn btn-lg btn-primary">Send
                                                    Message</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-4">
                                        <div className="position-relative h-100 d-flex align-items-center">
                                            <div className="overlay bg-rc" data-bg-img="assets/images/contact-info-bg.jpg"></div>
                                            <div className="overlay opacity-80 bg-primary"></div>
                                            <div className="right-column">
                                                <h5>Contact Information</h5>
                                                <div className="h-30px"></div>
                                                <div className="contact-info">
                                                    <div className="row align-items-center row-x-10 mb-2">
                                                        <div className="col-auto">
                                                            <i className="contact-info-icon flaticon-placeholder"></i>
                                                        </div>
                                                        <div className="col">
                                                            <p className="mb-0">138 West Street, <br />
                                                            Sandown, Sandton 2031</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row align-items-center row-x-10 mb-2">
                                                    <div className="col-auto">
                                                        <i className="contact-info-icon flaticon-smartphone"></i>
                                                    </div>
                                                    <div className="col">
                                                        <p className="mb-0">(+27) 787-917-317</p>
                                                    </div>
                                                </div> */}
                                                    <div className="row align-items-center row-x-10 mb-3">
                                                        <div className="col-auto">
                                                            <i className="contact-info-icon flaticon-message"></i>
                                                        </div>
                                                        <div className="col">
                                                            <p className="mb-0">info@codeadvanced.co.za</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="contact-social">
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <a href="https://web.facebook.com/codeadvanced" target="_blank" rel="noopener noreferrer">
                                                                <i className="icon fab fa-facebook-f"></i>
                                                            </a>
                                                        </li>
                                                        {/* <li className="list-inline-item">
                                                        <a href="#">
                                                            <i className="icon fab fa-instagram"></i>
                                                        </a>
                                                    </li> */}
                                                        <li className="list-inline-item">
                                                            <a href="https://twitter.com/CodeAdvanced1" target="_blank" rel="noopener noreferrer">
                                                                <i className="icon fab fa-twitter"></i>
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <a href="https://www.linkedin.com/company/codeadvanced" target="_blank" rel="noopener noreferrer">
                                                                <i className="icon fab fa-linkedin"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </>
        )
    };
}

export default ContactUs;