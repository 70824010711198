import React from "react"

const OurValues = () => {
    return (
        <>
            <section id="our-values" className="section our-values pt-60px">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-6 mx-auto text-center">
                            <h6 className="subtitle">OUR VALUES&nbsp;</h6>
                            <h2 className="h3 mb-4">What is important to us?</h2>
                            <p>We shape our culture and identity - principles, beliefs and philosophies based on our values.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-4 mb-5">
                            <div className="feature-item">
                                {/* <i className="icon red flaticon-magic-wand"></i> */}
                                <h5 className="h5 mb-3">Excellence</h5>
                                <p>We aim at giving our clients the best quality software engineered solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 mb-5">
                            <div className="feature-item">
                                <h5 className="h5 mb-3">Integrity</h5>
                                <p>You can always trust the Code Advanced team to get the work done.</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 mb-5">
                            <div className="feature-item">
                                <h5 className="h5 mb-3">Growth & Innovation</h5>
                                <p>We are always creating new products and staying ahead in technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurValues;