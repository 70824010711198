import React from "react"

const AboutUs = () => {
    return (
        <>
            <section id="aboutus" className="section feautred-service style-one pt-100px pb-60px">
                <div className="container text-center">
                    <div className="section-header row flex-column align-items-center mb-4">
                        <div className="col-lg-6">
                            <h6 className="subtitle">ABOUT US</h6>
                            <h2 className="h3">Why Code Advanced&nbsp;</h2>
                            <p>The Code Advanced team of top-notch professional developers and designers who have a deep
                            knowledge of information systems, methodologies and development technologies</p>
                        </div>
                    </div>
                    <div className="section-content row">
                        <div className="col-md-6 col-lg-3 mb-7">
                            <div className="service-item">
                                <i className="icon red flaticon-magic-wand"></i>
                                <h4 className="h5">Highly Experienced</h4>
                                <p>We are highly experienced in delivering smart digital solutions to cater for the needs
                                of our clients and help in their business enhancement.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-7">
                            <div className="service-item">
                                <i className="icon blue flaticon-friend"></i>
                                <h4 className="h5">Partnerships&nbsp;</h4>
                                <p>We partner with successful companies and convert their business ideas into meaningful
                                technology solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-7">
                            <div className="service-item">
                                <i className="icon orange flaticon-pie-chart"></i>
                                <h4 className="h5">Client Relationships</h4>
                                <p>While always engaging with clients and delivering software solutions that meet their
                                needs. We ensure a successful project delivery with personal Code Advanced touch.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-7">
                            <div className="service-item">
                                <i className="icon pink flaticon-padlock"></i>
                                <h4 className="h5">Quality Staff</h4>
                                <p>The quality of our staff and their track record of delivery speaks for itself; We pride
                                ourselves in maintaining the highest employment standards and activating our collective
                                potential</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;